import { map } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "./core.model";

const roleProp = 'role'; // "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const userIdProp = 'UserId';


export class Principal {
  private _token: JwtPayload;

  private _roles: string[];
  public UserId: string;
  constructor(token: string | null) {
    if (token == null) {
      this._token = {};
      this._roles = [];
      this.UserId = '';
      return;
    }
    this._token = this.decodedAccessToken(token) || {};
    this._roles = this.getRoles(this._token);
    this.UserId = this._token[userIdProp] || null;
  }

  private getRoles(tokenDecode: JwtPayload): string[] {
    if ( typeof tokenDecode[roleProp] === 'string' || tokenDecode[roleProp] instanceof String )
      return [tokenDecode[roleProp].toString()];
    if (Array.isArray(this._token[roleProp])) return tokenDecode[roleProp];
    return [];
  }
  public TokenValid(): boolean{
    const token = this._token;
    if(!token) return false;
    const exp = token['exp'] as number;
    return Date.now() <= exp * 1000;
  }

  private decodedAccessToken(token: string): JwtPayload | null {
    try {
      return jwt_decode<JwtPayload>(token);
    } catch (error) {
      return null;
    }
  }
  public IsRole(role: string) {
    for (let i = 0; i < this._roles.length; i++) {
      if (this._roles[i].toLowerCase() === role.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  public Token() {
    return this._token;
  }
  public Role() {
    return this._roles;
  }
}
function jwt_decode<T>(token: string): JwtPayload | null {
  const decoded = jwtDecode(token);
  return decoded;
}

